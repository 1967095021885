import { ABTestLink } from './builder.interfaces';

export function hasQueryParams(url: string) {
  return url.includes('?');
}

export function parseQueryParams(url: string) {
  const [path, queryString] = url.split('?');
  const query = new URLSearchParams(queryString);
  return { path, query };
}

/**
 * Merges query parameters in this order / presendence:
 * 1. Existing query parameters in the URL
 * 2. New query parameters passed in
 * 3. src parameter (if provided) is added, overwriting any existing src
 */
export function mergeQueryParams({
  url,
  query,
  src,
}: {
  url: string;
  query?: URLSearchParams | { [key: string]: string };
  src?: string;
}) {
  const { path, query: existingQuery } = parseQueryParams(url);
  const existingQueryAsObject = Object.fromEntries(existingQuery.entries());
  const newQueryAsObject =
    query instanceof URLSearchParams
      ? Object.fromEntries(query.entries())
      : query;
  const mergedQuery = new URLSearchParams({
    ...existingQueryAsObject,
    ...newQueryAsObject,
  });
  if (src) {
    mergedQuery.set('src', src);
  }
  // alphabetize the query params
  mergedQuery.sort();
  // don't append "?" unless there are some query params
  const queryString = mergedQuery.toString();
  return !queryString ? path : `${path}?${queryString}`;
}

/**
 * Transform a string such as "campaign/%campaignId%/details" into "campaign/123/details"
 * based on provided "replacements" object of { campaignId: 123 }
 */
export function replaceTokens(
  url: string,
  replacements?: { [key: string]: any },
) {
  // replace all tokens in URL with values %sometoken%
  const replacementsToMap = replacements || {};
  let finalUrl = url;
  Object.keys(replacementsToMap).forEach((key) => {
    const value = replacementsToMap[key];
    const token = `%${key}%`;
    finalUrl = finalUrl.replace(token, value);
  });
  return finalUrl;
}

export function buildDefaultUrlWithReplacements(
  {
    url,
    label,
    query,
    src,
    replacements,
  }: {
    url: string;
    label: string;
    query?: URLSearchParams | { [key: string]: any };
    src?: string;
    replacements?: { [key: string]: any };
  },
  reason: string,
): ABTestLink {
  const mergedQueryParams = mergeQueryParams({
    url,
    query,
    src,
  });
  const finalUrl = replaceTokens(mergedQueryParams, replacements);
  console.warn(
    `ABTestLink: since ${reason}, using fallback href of "${url}" & sending URL as "${finalUrl}"`,
  );
  return {
    url: finalUrl,
    label,
  };
}
