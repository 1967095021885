'use client';

import {
  replaceTokens,
  mergeQueryParams,
  buildDefaultUrlWithReplacements,
} from './url-utils';
import { builder } from './builder';
import { ContentTypes } from './builder.constants';
import { ABTestUrlProps, ABTestLink } from './builder.interfaces';

/**
 * Fetch a nav-item by ID from Builder.io and build a URL, applying the replacements
 * merging query parameters and adding src, if supplied
 *
 * NOTE: if this does not work as expected, CHECK THE BUILDER.IO API KEY
 * ensure that process.env actually contains the API key you are expecting
 * because the default key from config is prod
 */
export async function builderABTestLink({
  id,
  url,
  label,
  replacements,
  query,
  src,
}: ABTestUrlProps): Promise<ABTestLink> {
  console.debug(
    `ABTestLink: building AB Test URL for ${ContentTypes.NAV_ITEMS} with ID ${id}...`,
    {
      replacements,
      url,
      query,
      src,
    },
  );

  try {
    const builderParams = {
      url: 'undefined' !== typeof window ? window.location.href : '',
      query: {
        // id: id, // <-- this doesn't work, because ID is the internal DB id not our defined one
        data: {
          id,
        },
      },
    };
    console.debug(
      `ABTestLink: getting AB Test URL for ${ContentTypes.NAV_ITEMS} with ID ${id} using builder params:`,
      builderParams,
    );

    const res = await builder.get(ContentTypes.NAV_ITEMS, builderParams);
    const {
      data: { url: urlTemplate, name, label },
    } = res;
    if (!urlTemplate) {
      return buildDefaultUrlWithReplacements(
        { url, label, query, src, replacements },
        'URL was empty in response from Builder.io',
      );
    }

    console.debug(
      `ABTestLink: found ${ContentTypes.NAV_ITEMS} for ID ${id} OK:`,
      {
        name,
        label,
        url: urlTemplate,
      },
    );

    const mergedQueryParams = mergeQueryParams({
      url: urlTemplate,
      query,
      src,
    });
    const finalUrl = replaceTokens(mergedQueryParams, replacements);
    return {
      url: finalUrl,
      label,
    };
  } catch (err) {
    console.error(
      `ABTestLink: Error building AB Test URL for ${ContentTypes.NAV_ITEMS} with ID ${id}:`,
      err,
    );
    return buildDefaultUrlWithReplacements(
      { url, label, query, src, replacements },
      `error building AB Test URL for ${ContentTypes.NAV_ITEMS} with ID ${id}`,
    );
  }
}

export default builderABTestLink;
