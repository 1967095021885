import { ElasticSearchParams, ElasticSearchResult } from '@/src/types';

const elasticSearchBaseUrl = 'https://search.launchgood.com';

export class CampaignCardService {
  public static getCampaigns = async ({
    docType,
    coordinates,
    limit,
    offset = 0,
    randomize = true,
    src,
    type = 'discovery',
    almost_funded = false,
  }: ElasticSearchParams): Promise<ElasticSearchResult> => {
    const searchParams = CampaignCardService.generateSearchParams({
      docType,
      coordinates,
      limit,
      offset,
      randomize,
      src,
      type,
      almost_funded,
    });

    const res = await fetch(
      `${elasticSearchBaseUrl}/v2/production?${searchParams.toString()}`,
    );
    const data = await res.json();
    return data;
  };

  public static generateSearchParams(
    params: ElasticSearchParams,
  ): URLSearchParams {
    const searchParams = new URLSearchParams();
    searchParams.append('docType', params.docType);
    searchParams.append('index', 'launchgood_production_project');
    searchParams.append('f[]', `hide_community_campaigns:true`);
    if (params.almost_funded) {
      searchParams.append('f[]', `almost_funded:true`);
    }
    if (params.coordinates) {
      const paramValue = `near_me:${params.coordinates.latitude}|${params.coordinates.longitude}`;
      searchParams.append('f[]', paramValue);
    }
    ['limit', 'offset', 'randomize', 'src', 'type'].forEach((paramName) => {
      const paramValue = params[paramName as keyof ElasticSearchParams];
      if (paramValue) {
        searchParams.append(paramName, paramValue.toString());
      }
    });

    return searchParams;
  }
}
