import { request } from '@/src/lib/api/v3/client/core/request';
import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import { general_api_response_body } from '../../api';
import { GuestUser } from '@/src/types';

export class UserService {
  // the createGuestUser service in the OpenApi generated service is poiting to the wrong url, hence this custom service.
  public static createGuestUser = async (requestBody: {
    verb: string;
    email: string;
    name: string;
  }): Promise<
    general_api_response_body & {
      result?: {
        id: number;
        type: string;
        hash: string;
      };
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/user/guest',
      body: requestBody,
    });
  };
}

export const getGeoLocation = async () => {
  const response = await fetch(
    `https://geolocation-db.com/json/0daad5e0-82e7-11ee-92e0-f5d620c7dcb4`,
  );
  const data = await response.json();
  return data;
};
