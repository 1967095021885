import { general_api_response_body, OpenAPI } from '@/src/lib/api/v3';
import { request } from '@/src/lib/api/v3/client/core/request';
import { Currency, ExchangeRateResponse } from '@/src/types';
import { DateTime } from 'luxon';

export const exchangeRateCache: Record<string, ExchangeRateResponse> = {};

export class FxService {
  public static getSupportedCurrencies = async (): Promise<
    general_api_response_body & { result: string[] }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/fulladmin/currencies',
    });
  };

  public static exchangeRate = async (
    from: Currency,
    to: Currency,
  ): Promise<ExchangeRateResponse> => {
    if (from === to)
      return Promise.resolve({ from, to, rate: 1, createdAt: DateTime.now() });

    const key = `${from}-${to}`;

    if (exchangeRateCache[key]) {
      const now = DateTime.now();
      const diff = now.diff(exchangeRateCache[key].createdAt, 'hours').hours;
      if (diff < 24) {
        return Promise.resolve(exchangeRateCache[key]);
      }
    }

    const res: general_api_response_body & { result: ExchangeRateResponse } =
      await request(OpenAPI, {
        method: 'GET',
        url: `/project/data/exchange-rate/${from}/${to}`,
      });
    const { result } = res;
    exchangeRateCache[key] = {
      ...(result as ExchangeRateResponse),
      createdAt: DateTime.now(),
    };
    return exchangeRateCache[key];
  };
}
