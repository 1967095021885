import config from '@/src/config';
import {
  CommunityAggregate,
  CommunityResponse,
} from '../../app/(views)/fundraising/dashboard/components/Community.interface';

const communitySearchApiUrl = `${config().lg3OpenSearchUrl}/cards/community`;

export class CommunityService {
  public static getLiveCommunities = async (
    accountId: number,
  ): Promise<CommunityResponse> => {
    const token = localStorage.getItem('token');
    const res = await fetch(
      `${communitySearchApiUrl}/by-account-id/${accountId}?community_status=active&limit=3`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCommunitiesByAccount = async (
    accountId: number,
  ): Promise<CommunityResponse> => {
    const token = localStorage.getItem('token');
    const res = await fetch(
      `${communitySearchApiUrl}/by-account-id/${accountId}?limit=300`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };

  public static getCommunitiesAggregateByAccount = async (
    accountId: number,
  ): Promise<CommunityAggregate> => {
    const token = localStorage.getItem('token');
    const res = await fetch(
      `${communitySearchApiUrl}/by-account-id/${accountId}?aggregate_statuses=true&limit=0`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const data = await res.json();
    return data;
  };
}
