import { OpenAPI } from '@/src/lib/api/v3/client/core/OpenAPI';
import { request } from '@/src/lib/api/v3/client/core/request';

import { Payout } from '../interfaces/payout.interface';
import { general_api_response_body } from '../api/v3/client/models/general_api_response_body';

export class PayoutService {
  public static getPayoutsData = async ({
    accountId,
  }: {
    accountId: number;
  }): Promise<
    general_api_response_body & {
      result?: {
        transfers: Payout[];
      };
    }
  > => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/api/account/transfers',
      body: {
        account_id: accountId,
      },
    });
  };
}
