/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CampaignBasics,
  Card,
  Currency,
  PlanInfo,
  SubscriptionStatus,
} from '@/src/types';
import {
  MenuContextProps,
  RamadanContextProps,
  SubscriptionContextProps,
  UserContextProps,
  DhcContextProps,
} from './interfaces';
import { SetStateAction } from 'react';
import {
  emptyCampaignBasics,
  emptyCard,
  planInfoInit,
} from '../constants/sg-constants';

export const defaultUserState: UserContextProps = {
  userId: 0,
  email: '',
  username: '',
  displayName: 'Anonymous Kind Soul',
  firstName: '',
  profileImageSrc: '',
  currency: 'USD',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrency: (currency: Currency) => {}, // eslint-disable-line @typescript-eslint/no-empty-function
};

export const defaultSubscriptionState: SubscriptionContextProps = {
  givingPlanId: 0,
  setGivingPlanId: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  donationAmount: 0,
  setDonationAmount: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  donationAmountCurrency: '',
  setDonationAmountCurrency: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  tipAmount: 0,
  setTipAmount: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  tipAmountCurrency: '',
  setTipAmountCurrency: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  cardId: 0,
  setCardId: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  cardType: '',
  setCardType: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  loggedIn: false,
  setLoggedIn: function (value: SetStateAction<boolean>): void {
    throw new Error('Function not implemented.');
  },
  subscriptionStatus: SubscriptionStatus.INACTIVE,
  setSubscriptionStatus: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  card: emptyCard,
  setCard: function (value: SetStateAction<Card>): void {
    throw new Error('Function not implemented.');
  },
  dropdownValue: '',
  setDropdownValue: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  additionalDonationAmount: 0,
  setAdditionalDonationAmount: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  additionalTipAmount: 0,
  setAdditionalTipAmount: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  donationAmountCurrencySymbol: '$',
  setDonationAmountCurrencySymbol: function (
    value: SetStateAction<string>,
  ): void {
    throw new Error('Function not implemented.');
  },
  referralCode: '',
  setReferralCode: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  referralSource: '',
  setReferralSource: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  campaignId: 0,
  setCampaignId: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
  givingCategories: [],
  setGivingCategories: function (value: SetStateAction<string[]>): void {
    throw new Error('Function not implemented.');
  },
  referralCampaign: emptyCampaignBasics,
  setReferralCampaign: function (value: SetStateAction<CampaignBasics>): void {
    throw new Error('Function not implemented.');
  },
  enrolledChallenges: [],
  setEnrolledChallenges: function (value: SetStateAction<string[]>): void {
    throw new Error('Function not implemented.');
  },
  subscriptionDate: '',
  setSubscriptionDate: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  subscriptionLoaded: false,
  setSubscriptionLoaded: function (value: SetStateAction<boolean>): void {
    throw new Error('Function not implemented.');
  },
  timeToNextCharge: '',
  setTimeToNextCharge: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  isAmex: false,
};

export const defaultMenuState: MenuContextProps = {
  menuItems: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggleMenu: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
};

export const defaultRamadanState: RamadanContextProps = {
  plan: '',
  setPlan: function (value: SetStateAction<string>): void {
    throw new Error('Function not implemented.');
  },
  ramadanInfo: planInfoInit,
  setRamadanInfo: function (value: SetStateAction<PlanInfo>): void {
    throw new Error('Function not implemented.');
  },
};

export const defaultDhcState: DhcContextProps = {
  dhcInfo: planInfoInit,
  setDhcInfo: function (value: SetStateAction<PlanInfo>): void {
    throw new Error('Function not implemented.');
  },
  dhcDay: 0,
  setDhcDay: function (value: SetStateAction<number>): void {
    throw new Error('Function not implemented.');
  },
};
