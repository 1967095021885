import { Authenticator, HashAlgorithms, KeyEncodings } from '@otplib/core';
import { createDigest, createRandomBytes } from '@otplib/plugin-crypto-js';
import { keyDecoder, keyEncoder } from '@otplib/plugin-thirty-two';
import ReCAPTCHA from 'react-google-recaptcha';

export class RecaptchaService {
  /**
   * Generates a temporary one time password (TOTP) which can be passed INSTEAD of recaptcha.
   * This requires use of a shared secret between client and server.
   * We do this so that automated testing tools can bypass recaptcha in production and other environments
   * where it is not workable to just turn the whole thing off.
   * Docs for the following implementation can be found here
   * https://github.com/yeojz/otplib/blob/master/README.md#quick-start
   **/
  public static generateTotp = (key: string): string => {
    try {
      const auth = new Authenticator({
        algorithm: HashAlgorithms.SHA1,
        digits: 6,
        step: 30,
        epoch: Date.now(),
        encoding: KeyEncodings.HEX,
        window: 0,
        createRandomBytes,
        createDigest,
        keyDecoder,
        keyEncoder,
      });
      return auth.generate(key);
    } catch (e) {
      console.error('Could not generate Totp from given shared secret!');
      throw e;
    }
  };

  public static generateRecaptcha = async (
    recaptchaCurrentRef: ReCAPTCHA | null,
  ): Promise<string | null> => {
    const reCaptchaSharedSecret = localStorage.getItem('totpSharedSecret');
    if (reCaptchaSharedSecret) {
      return this.generateTotp(reCaptchaSharedSecret);
    }

    if (!recaptchaCurrentRef) {
      console.warn(
        'Recaptcha ref yet undefined; possibly recaptcha has not loaded yet',
      );
      throw new Error('Cannot generate reCaptcha due to undefined current ref');
    }
    recaptchaCurrentRef.reset();
    return await recaptchaCurrentRef.executeAsync();
  };
}
