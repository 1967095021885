'use client';
import { createContext, useContext } from 'react';
import {
  SubscriptionContextProps,
  UserContextProps,
  MenuContextProps,
  RamadanContextProps,
  DhcContextProps,
} from './interfaces';
import {
  defaultMenuState,
  defaultRamadanState,
  defaultSubscriptionState,
  defaultUserState,
  defaultDhcState,
} from './constants';

//creating the Context, Providers are on the client side
//Provider is in SitewideHeader.tsx
export const MenuItemsContext =
  createContext<MenuContextProps>(defaultMenuState);
//Provider is in root-layout.tsx
export const UserContext = createContext<UserContextProps>(defaultUserState);
//Provider is in SubscriptionContext.tsx
export const SubscriptionContext = createContext<SubscriptionContextProps>(
  defaultSubscriptionState,
);
export const RamadanContext =
  createContext<RamadanContextProps>(defaultRamadanState);

export const DhcContext = createContext<DhcContextProps>(defaultDhcState);
//creating the Consumer as custom hooks
export const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('useUserContext must be used within a UserContext');
  }

  return userContext;
};

export const useSubscriptionContext = () => {
  const subscriptionContext = useContext(SubscriptionContext);
  if (!subscriptionContext) {
    throw new Error(
      'useSubscriptionContext must be used within a SubscriptionProvider',
    );
  }

  return subscriptionContext;
};

export const useRamadanContext = () => {
  const ramadanContext = useContext(RamadanContext);
  if (!ramadanContext) {
    throw new Error('useRamadanContext must be used within a RamadanProvider');
  }

  return ramadanContext;
};

export const useDhcContext = () => {
  const dhcContext = useContext(DhcContext);
  if (!dhcContext) {
    throw new Error('useDhcContext must be used within a DhcProvider');
  }

  return dhcContext;
};
